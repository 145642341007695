<script setup>

const app_config = useAppConfig()

useSeoMeta({
    title: 'ArendaBC - Портал коммерческой недвижимости',
})

const homeStore = useHomeStore()
const metroStore = useMetrosStore();
const raionStore = useRaionsStore();

nextTick( async () => {
    await homeStore.getItem( 57 )
})

onMounted(async () => {
    await metroStore.getList()
    await raionStore.getList()
})

const check_offertype = ref('arenda')
const check_offertype_header = ref('Аренда')
const check_type = ref('ofisy')
const check_type_header = ref('офисных помещений')
const check_mode = ref('metro')
const check_mode_raion_header = ref('')

const check_types = ref(null)

// Выбор типа
const select_type = type => {
    check_type.value = type.slug;
    check_type_header.value = type.vin
}

// Выбор раион
const select_raion = raion => { 
    check_mode_raion_header.value = raion.header
} 

// Выбор офферта
const select_offertype = offertype => {
    if (app_config[offertype]) {
        let data_offertype = app_config[offertype]
        check_types.value = data_offertype.types.map(e => ({ label: e.label, slug: e.slug , vin: e.vin }));
        check_offertype.value = offertype
        check_offertype_header.value = data_offertype.header
    }
}
select_offertype('arenda')
const select_mode = mod => {
    check_mode.value = mod
}


</script>

<template>

    <section class="relative bg-brand-bright">
        <div class="absolute inset-0 bg-[url('public/img/main-bg2.jpg')] bg-no-repeat bg-cover bg-center bg-gray-600 bg-opacity-50 z-0"></div>
        <div class="absolute top-1 bottom-[1px] left-0 right-0 bg-[url('public/img/main-bg2.jpg')] bg-no-repeat bg-cover bg-center bg-gray-600 bg-opacity-50 z-[5] filter blur-[3px]"></div>
        <div class="container relative z-10 ">
            <div class="py-10">
                <h1 class="md:text-3xl text-lg mb-6 text-center text-white font-extrabold uppercase">
                    Коммерческая недвижимость {{ app_config.city.rod }} на
                    портале <span class="text-white">ARENDABC</span>
                </h1>
                <div class="flex items-center gap-2 justify-start mb-4 py-1 px-2 rounded-lg bg-[#00000044] w-fit">
                    <svg width="13px" height="13px" viewBox="0 0 23 23" id="meteor-icon-kit__solid-location-arrow"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="" stroke-width="0"></g>
                        <g id="" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M0.755196 8.8346L21.7285 -0.038704C21.9775 -0.144081 22.2587 -0.144081 22.5077 -0.038704C23.0164 0.17649 23.2543 0.76327 23.0391 1.2719L14.1658 22.2452C14.0434 22.5345 13.7921 22.7495 13.4873 22.8257C12.9515 22.9596 12.4086 22.6339 12.2747 22.0981L10.0002 13.0002L0.9023 10.7257C0.597527 10.6495 0.346275 10.4345 0.223868 10.1452C0.0086761 9.6366 0.246559 9.0498 0.755196 8.8346z"
                                fill="#ffffff"></path>
                        </g>
                    </svg>
                    <span class="text-base text-white">{{ app_config.city.im }}</span>
                </div>
                <Filter :autorun="false" />
                <LayoutsTypesCards />
                <LayoutsCatalogNavs />
                <!-- <LayoutsTypesArenda /> -->
            </div>
        </div>
    </section>

    <section class="py-6 bg-gray-100">
        <div class="container md:my-10 my-5">
            <div class="md:grid hidden grid-cols-4 gap-6">
                <div class="h-full col-span-1">

                    <div data-offertypes class="w-full gap-2 overflow-hidden rounded">
                        <div @click="select_offertype('arenda')" class="w-full p-3 block cursor-pointer bg-white"
                            :class="(check_offertype == 'arenda') ? 'text-blue-400 underline' : 'text-dark'">
                            <span class="text-sm ms-2">Аренда</span>
                        </div>
                        <div @click="select_offertype('prodaja')"
                            class="w-full p-3 block -mt-0.5 cursor-pointer bg-white"
                            :class="(check_offertype == 'prodaja') ? 'text-blue-400 underline' : 'text-dark'">
                            <span class="text-sm ms-2">Продажа</span>
                        </div>
                    </div>

                    <div data-types class="mt-6 w-full gap-2 overflow-hidden rounded">
                        <template v-for="type in check_types">
                            <div @click="select_type(type)" class="w-full p-3 block cursor-pointer bg-white"
                                :class="(check_type == type.slug) ? 'text-blue-400 underline' : 'text-dark'">
                                <span class="text-sm ms-2">{{ type.label }}</span>
                            </div>
                        </template>
                    </div>

                    <div data-mode class="mt-6 w-full gap-2 overflow-hidden rounded">
                        <div @click="select_mode('metro')" class="w-full p-3 block cursor-pointer bg-white"
                            :class="(check_mode == 'metro') ? 'text-blue-400 underline' : 'text-dark'">
                            <span class="text-sm ms-2">Метро</span>
                        </div>
                        <div @click="select_mode('raion')" class="w-full p-3 block -mt-0.5 cursor-pointer bg-white"
                            :class="(check_mode == 'raion') ? 'text-blue-400 underline' : 'text-dark'">
                            <span class="text-sm ms-2">Район</span>
                        </div>
                    </div>

                    <FormsPodbor type="desctop"/>

                </div>
                <div class="w-auto bg-white h-full rounded col-span-3">

                    <template v-for="offertype in ['arenda', 'prodaja']">
                        <template v-for="type in check_types">
                            <template v-for="mode in ['metro', 'raion']">
                                <template v-if="mode == 'metro'">
                                    <div data-metro
                                        :class="(check_mode == 'metro' && check_offertype == offertype && check_type == type.slug) ? '' : 'hidden'">
                                        <h2 class="text-lg text-slate-800 font-normal px-10 pt-5 pb-0">
                                            <span class="uppercase inline-block pr-1 text-base">{{ check_offertype_header }}</span>
                                            <span class="uppercase inline-block pr-1 text-base">{{ check_type_header}}</span> 
                                        </h2>
                                        <div class="max-h-[100vh] p-5 flex justify-center">
                                            <div :style="{ backgroundImage: `url('/img/metrospb.png')` }"
                                                class="relative w-[870px] h-[678px] pb-8 bg-no-repeat bg-[position:112px_9px]">
                                                <div v-for="metro in metroStore.items" :key="metro.id"
                                                    :style="{ top: `${metro.position_top}`, left: `${metro.position_left}` }"
                                                    class="stName rt  absolute -mt-1 py-1 px-2 max-w-[195px]">
                                                    <a :href="`/${offertype}/${type.slug}/metro-${metro.slug}`"
                                                        class="cursor-pointer text-xs leading-3 inline-block p-1 rounded-md  hover:bg-[rgba(0,0,0,0.1)]">{{
                                                            metro.header }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="mode == 'raion'">
                                    <div data-metro
                                        :class="(check_mode == 'raion' && check_offertype == offertype && check_type == type.slug) ? '' : 'hidden'">
                                        <div class="p-5  flex justify-center">
                                            <div class="pb-8">

                                                <div v-for="raion in raionStore.items" :key="raion.id" class="hidden">
                                                    <a :href="`/${offertype}/${type.slug}/${raion.slug}-raion`"
                                                        class="cursor-pointer uppercase text-xs leading-3 inline-block p-1 rounded-md  hover:bg-[rgba(0,0,0,0.1)]">
                                                        {{ raion.header }}
                                                    </a>
                                                </div>

                                                <h2 class="text-lg pb-2 text-slate-800 font-normal">
                                                    <span class="uppercase inline-block pr-1 text-base">{{ check_offertype_header }}</span>
                                                    <span class="uppercase inline-block pr-1 text-base">{{ check_type_header}}</span> 
                                                </h2>
                                                <div>
                                                    <map name="polygons">
                                                        <template v-for="raion in raionStore.items">
                                                            <area 
                                                                :href="`/${check_offertype}/${check_type}/${raion.slug}-raion`"
                                                                shape="poly" 
                                                                :coords="raion?.data"
                                                                :alt="raion.header" 
                                                                @click="select_raion(raion)"
                                                            />
                                                        </template>
                                                    </map>
                                                    <img src="/img/raiony_spb_1.png" usemap="#polygons" alt="Районы">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </template>
                    </template>

                </div>
            </div>
            <!-- mobile -->
            <div class="md:hidden block">

                <form class="p-4 bg-white rounded-lg">
                    <div class="flex flex-col gap-2 justify-between">
                        <select @change="select_offertype($event.target.value)" v-model="check_offertype"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected value="arenda">Аренда</option>
                            <option value="prodaja">Продажа</option>
                        </select>
                        <select
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option v-for="(type, index) in check_types" :key="index" :value="type.slug"
                                :selected="index === 0">
                                {{ type.label }}
                            </option>
                        </select>
                    </div>
                    <div class="w-full mt-6">
                        <button type="button"
                            class="text-white bg-brand-primary py-1.5 px-4 md:py-2.5 md:px-12 w-full rounded-lg font-medium hover:bg-brand-primary-hover">
                            <p class="block text-base">Поиск</p>
                        </button>
                    </div>
                </form>

                <FormsPodbor type="mobile"/>

            </div>
        </div>
    </section>

    <section class="bg-gray-50 py-3 md:py-10 relative" v-if="false">
        <div class="absolute inset-0 bg-[url('public/img/promo/bg.jpg')] bg-no-repeat bg-cover bg-center bg-gray-600 bg-opacity-50 z-0"></div>
        <div class="absolute top-1 bottom-[1px] left-0 right-0 bg-[url('public/img/promo/bg.jpg')] bg-no-repeat bg-cover bg-center bg-gray-600 bg-opacity-50 z-[5] filter blur-[3px]"></div>
        
        <div class="container z-10">

            

            <!-- <hr class="relative my-6 z-[6]" /> -->

            <LayoutsVstroenniyPomeshenia />

        </div> 
    </section>
    
    <section class="about sm:bg-white py-3 md:py-10">
        <div class="container rounded flex items-center gap-6">
            <img class="hidden lg:block" src="../public/img/about.png" alt="about">
            <div
                class="md:mt-3 p-3 md:p-2.5  md:pb-6 md:pt-6 md:bg-transparent md:border-0 border border-custom-gray rounded-lg max-[1024px]:bg-[url('/public/img/aboutbg.png')] bg-cover bg-white">
                
                <div v-html="homeStore.item.body">
                    
                </div>

            </div>
        </div>
    </section>

    <section class="container" v-if="false">
        <div
            class="bg-white  mt-3 p-3 md:p-2.5 md:pb-0 md:pt-6 md:bg-transparent md:border-0 border border-custom-gray rounded-lg bg-(url('/img/aboutbg.png'))">
            <LayoutsNewsObjects />
        </div>
    </section>

</template>

<style scoped>
#YMapsID {
    width: 100%;
    height: 400px;
    /* Задайте высоту для виджета карты */
}
.multiselect__tags{
    @apply bg-gray-50
}
</style>